<template>
  <div class="popup-notify">
    <b-modal no-close-on-backdrop class="mt-5" hide-footer ref="popup-notify">
      <template v-slot:modal-header>
        <div class="custom-modal-title">
          <i class="flaticon2-bell-1 mr-2 icon-style"></i>
          <h4 class="d-inline">Thông báo</h4>
        </div>
      </template>
      <div>
          <i class="flaticon2-percentage text-warning mr-2 icon-style"></i>
          <h5 class="mt-2 d-inline"> Chương trình khuyến mãi Marketing tháng {{currentMonth}}</h5>
             <a class="d-block my-3" target="_blank" href="https://docs.google.com/spreadsheets/d/1vxRlLEpI9il0OX_8lVBTK6FlkzQ4z_g7NNIGcSszZAM/edit#gid=139020473"><span class="content-size">Xem tại đây</span></a>
      </div>
      <b-row class="mt-5">
        <b-col><b-button size="sm" block variant="primary" @click="hidePopupNotify">Ẩn thông báo</b-button></b-col>
        <b-col><b-button size="sm" block variant="outline-danger" @click="togglePopupNotify">Bỏ qua</b-button></b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { setCookie, getValueCookie } from '@/utils/cookie';
import newVersionConfig from '@/core/config/_layout.config.json';

export default {
  name: 'PopupNotify',
  watch: {
    $route: {
      immediate: true,
      handler() {
        setTimeout(() => {
          this.showPopupNotify();
        }, 2000);
      }
    }
  },
  data() {
    return {
      currentMonth: new Date().getMonth() + 1,
      CONFIG_LAYOUT: newVersionConfig,
    }
  },
  methods: {
    setCookie,
    getValueCookie,
    togglePopupNotify() {
      this.$refs['popup-notify'].hide();
    },
    hidePopupNotify() {
      this.setCookiePopupNotify();
      this.$refs['popup-notify'].hide();
    },
    showPopupNotify() {
      const value = this.getValueCookie('set_popup_notify');
      if(!value) {
        this.$refs['popup-notify'].show();
      }
    },
    setCookiePopupNotify() {
      const currentDate = moment().format("YYYY-MM-DD")
      const torrowDate = moment(currentDate,"YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD")

      const expires = moment(torrowDate + "T07:00:00").toDate();

      const name = 'set_popup_notify';
      const value = true;

      this.setCookie(name, value, { expires })
    },
  },
  computed: { 
  }
};
</script>

<style lang="scss">
.popup-notify {
  .custom-modal-title, .custom-popup-notify {
    .icon-style {
      font-size: 1.35rem;
      color: black;
    }
    .content-size {
      font-size: 1.1rem;
    }
  }
}
</style>
