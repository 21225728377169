<template>
  <b-dropdown size="sm" right class="more-options" no-caret>
    <template slot="button-content">
      <i style="font-size: 1.2rem; padding-right: 0; color: white" class="flaticon2-add-1"></i>
    </template>
    <b-dropdown-item v-if="checkPermission('PRODUCT_INSERT')">
      <router-link to="/products/add-product">
        <span>
          <i style="font-size: 1rem" class="flaticon2-add-1"></i>
          &nbsp; Sản phẩm</span
        >
      </router-link>
    </b-dropdown-item>
    <b-dropdown-item v-if="checkPermission('ORDER_INSERT')">
      <router-link to="/orders/add-order">
        <span>
          <i style="font-size: 1rem" class="flaticon2-add-1"></i>
          &nbsp; Đơn hàng</span
        >
      </router-link>
    </b-dropdown-item>
    <b-dropdown-item v-if="checkPermission('BILL_INSERT')">
      <router-link to="/bills/add-bill">
        <span>
          <i style="font-size: 1rem" class="flaticon2-add-1"></i>
          &nbsp; Bán lẻ</span
        >
      </router-link>
    </b-dropdown-item>
    <b-dropdown-item v-if="checkPermission('BILL_INSERT')">
      <router-link to="/wholesaleInvoices/add-wholesale-invoice">
        <span>
          <i style="font-size: 1rem" class="flaticon2-add-1"></i>
          &nbsp; Bán buôn</span
        >
      </router-link>
    </b-dropdown-item>
    <b-dropdown-item v-if="checkPermission('STOCK_IMPORT_INSERT')">
      <router-link to="/stocks/add-stock">
        <span>
          <i style="font-size: 1rem" class="flaticon2-add-1"></i>
          &nbsp; Nhập kho</span
        >
      </router-link>
    </b-dropdown-item>
    <b-dropdown-item v-if="checkPermission('STOCK_EXPORT_INSERT')">
      <router-link to="/export-stocks/add-export-stock">
        <span>
          <i style="font-size: 1rem" class="flaticon2-add-1"></i>
          &nbsp; Xuất kho</span
        >
      </router-link>
    </b-dropdown-item>
    <b-dropdown-item v-if="checkPermission('STOCK_INSERT')">
      <router-link to="/transfer-stocks/add-transfer-stock">
        <span>
          <i style="font-size: 1rem" class="flaticon2-add-1"></i>
          &nbsp; Chuyển kho</span
        >
      </router-link>
    </b-dropdown-item>
    <b-dropdown-item>
      <a
        class="font-weight-bold"
        :href="URL_OFFER_SUGGESTION_FORM"
        target="_blank"
      >
        <span>
          <i style="font-size: 1rem" class="flaticon2-add-1"></i>
          &nbsp; Ý kiến đóng góp</span
        >
      </a>
    </b-dropdown-item>
    <b-dropdown-item
      class="text-primary"
      :href="URL_PROMOTION_PROGRAM_MARKETING"
      target="_blank"
    >
      <span class="text-primary">
        <i style="font-size: 1rem" class="flaticon2-add-1"></i>
        &nbsp; CTKM Marketing</span
      >
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { checkPermission, checkPermissions } from '@/utils/saveDataToLocal';
import {
  URL_OFFER_SUGGESTION_FORM,
  URL_PROMOTION_PROGRAM_MARKETING
} from '@/utils/constants';
export default {
  data() {
    return {
      URL_OFFER_SUGGESTION_FORM,
      URL_PROMOTION_PROGRAM_MARKETING,
    }
  },
  methods: {
    checkPermission,
    checkPermissions
  }
};
</script>

<style></style>
