var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu-nav"},[(false)?_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v("Dashboard")])])])]}}],null,false,4259514306)}):_vm._e(),(
      _vm.checkPermission('PRODUCT_VIEW') ||
      _vm.checkPermission('CATE_VIEW') ||
      _vm.checkPermission('PROPERTY_VIEW') ||
      _vm.checkPermission('PROVIDER_VIEW') ||
      _vm.checkPermission('BRAND_VIEW')
    )?_c('router-link',{attrs:{"to":"/products"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v("Sản phẩm")])])])]}}],null,false,81844077)}):_vm._e(),(_vm.checkPermission('CUSTOMER_VIEW'))?_c('router-link',{attrs:{"to":"/customers"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v("Khách hàng")])])])]}}],null,false,1748641102)}):_vm._e(),(_vm.checkPermission('STOCK_VIEW'))?_c('router-link',{attrs:{"to":"/stocks"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('small',{staticClass:"menu-text"},[_vm._v("Kho hàng")])])])]}}],null,false,2230944408)}):_vm._e(),(_vm.checkPermissions(['ORDER_VIEW']))?_c('router-link',{attrs:{"to":"/orders"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v("Đơn hàng")])])])]}}],null,false,8694425)}):_vm._e(),(_vm.checkPermission('BILL_VIEW'))?_c('router-link',{attrs:{"to":"/bills"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v("Bán hàng")])])])]}}],null,false,1934453898)}):_vm._e(),(_vm.checkPermission('BILL_VIEW'))?_c('router-link',{attrs:{"to":"/mbf-transaction"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_c('span',{staticClass:"text-primary"},[_vm._v("Mobi")]),_c('span',{staticClass:"text-danger"},[_vm._v("fone")])])])])]}}],null,false,4041132446)}):_vm._e(),(_vm.checkPermissions(['TRADE_IN_VIEW']))?_c('router-link',{attrs:{"to":"/trade-in"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v("Thu cũ")])])])]}}],null,false,926913413)}):_vm._e(),(
      _vm.checkPermissions([
        'TRANSACTION_VIEW',
        'INSTALLMENT_VIEW',
        'DEBT_CREDIT_VIEW',
        'DEBT_INSTALLMENT_VIEW',
        'DEBT_ACCOUNTANT_VIEW',
        'ACCOUNTANT_VIEW',
      ])
    )?_c('router-link',{attrs:{"to":"/accounting/transactions/cashbook"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v("Kế toán")])])])]}}],null,false,3660250246)}):_vm._e(),(_vm.checkPermissions(['REPORT_VIEW']))?_c('router-link',{attrs:{"to":"/reports"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v("Báo cáo")])])])]}}],null,false,239987687)}):_vm._e(),(_vm.checkPermissions(['REWARD_VIEW']))?_c('router-link',{attrs:{"to":"/reward/reward-category"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v("Hoa hồng")])])])]}}],null,false,966290642)}):_vm._e(),(
      _vm.checkPermission('STORE_VIEW') || _vm.checkPermission('ORDER_SOURCE_VIEW')
    )?_c('router-link',{attrs:{"to":"/stores"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v("Cấu hình")])])])]}}],null,false,2800692823)}):_vm._e(),_c('div',[_c('b-form-select',{staticClass:"dropdown-custom",attrs:{"size":"sm","options":_vm.merchantOptions},on:{"change":_vm.onChangeMerchant},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("-- Chọn công ty --")])]},proxy:true}]),model:{value:(_vm.selectedMerchant),callback:function ($$v) {_vm.selectedMerchant=$$v},expression:"selectedMerchant"}})],1),_c('b-dropdown',{staticClass:"quick-link",attrs:{"size":"sm","right":"","no-caret":""}},[_c('template',{slot:"button-content"},[_c('i',{staticClass:"fa-solid fa-circle-plus ml-1 text-dark"})]),_vm._l((_vm.dropDownLink),function(item,index){return [(
          item.permit && item.permit.length
            ? _vm.checkPermissions(item.permit)
            : true
        )?[_c('b-dropdown-item',{key:index,attrs:{"to":item.external
              ? item.link
              : { path: item.link, target: item.target || '' },"href":item.external ? item.link : '',"target":item.target || ''}},[_c('span',[_c('i',{staticClass:"fa-solid fa-arrow-up-right-from-square text-dark mr-1",staticStyle:{"font-size":"1rem"}}),_vm._v(" "+_vm._s(item.name))])])]:_vm._e()]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }